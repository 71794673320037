<template>
  <div>
    <template v-if="tourinfo.course_image_file_1.length > 0">
      <div
        class="sectionBackground"
        :style="{
          backgroundImage:
            'url(' + DEFIMAGESAMS3 + 'sst' + '/' + 'courses' + '/' +
            id +
            '/' +
            tourinfo.course_code +
            '/' +
            tourinfo.course_image_file_1 +
            ')',
        }"
      >
        <b-container>
          <b-row class="topPad">
          </b-row>
          <div v-if="isMobile(true)">
            <b-row cols="yellowOuterBlock">
              <b-col cols="12" class="yellowBlock"></b-col>
            </b-row>
            <b-row class="toptournInfo">
              <template v-if="tourinfo.winner_found == 'Y'">
                <b-col
                  lg="4"
                  class="winnerProfile-Image"
                  :style="{
                    backgroundImage:
                      'url(' +
                      DEFIMAGESAMS3 + 'sst' + '/media/photos/' +
                      tourinfo.winner_code +
                      '.jpg' +
                      ')',
                  }"
                >
                  <div class="over"></div>
                </b-col>
              </template>
              <template v-else-if="tourinfo.previous_winners == ''">
                <b-col
                  class="backgroundTourImg courseCodeImage"
                  lg="4"
                  :style="{
                    backgroundImage:
                      'url(' + DEFIMAGESAMS3 + 'sst' + '/' + 'courses' + '/' +
                        id +
                        '/' +
                        tourinfo.course_code +
                        '/' +
                        tourinfo.course_image_file_1 +
                      ')',
                  }"
                >
                </b-col>
              </template>
              <template v-else>
                <b-col
                  lg="4"
                  class="winnerProfile-Image"
                  :style="{
                    backgroundImage:
                      'url(' +
                      DEFIMAGESAMS3 + 'sst' + '/media/photos/' +
                      tourinfo.previous_winners.previous_winners_entry.cc_code_1 +
                      '.jpg' +
                      ')',
                  }"
                >
                  <div class="over"></div>
                </b-col>
              </template>
              <b-col class="reportInfo" lg="8">
                <b-row class="dateTitle">
                  <b-col lg="8">
                    <h3 class="reportDate">
                      {{ tourinfo.dates }}
                    </h3>
                    <h3 class="reportTitle">
                      {{ tourinfo.full_name }}
                    </h3>
                    <h3 class="courseName">
                      {{ tourinfo.course_name }}
                    </h3>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="secondInfo">
                  <b-col lg="4">
                    <p class="valueReport">
                      {{ tourinfo.rounds }}
                    </p>
                    <p class="listTourInfo">Number of Rounds</p>
                  </b-col>
                  <b-col lg="4" v-if="tourinfo.total_prize_fund.length">
                    <p class="valueReport">
                      {{ tourinfo.total_prize_fund }}
                    </p>
                    <p class="listTourInfo">Prize Fund</p>
                  </b-col>
                </b-row>
                <b-row class="secondInfo" v-if="PlayerLogged !== 'guest'">
                  <b-col lg="4">
                    <p class="listTourInfo">Entry Status</p>
                    <p class="valueReport">
                      <span v-if="tourinfo.entry_closed === 'Y'">
                        Entries Closed
                      </span>
                      <span v-else> Entries Open </span>
                    </p>
                  </b-col>
                  <b-col lg="4">
                    <p class="listTourInfo">Your Status</p>
                    <p
                      class="valueReport"
                      v-if="tourinfo.entry_status.length > 0"
                    >
                      {{ currentStatus(tourinfo.entry_status) }}
                    </p>
                    <p class="valueReport" v-else>N/A</p>
                  </b-col>
                  <b-col lg="4">
                    <p class="listTourInfo">Entry Fee</p>
                    <p class="valueReport">
                      {{ tourinfo.entry_fee }}
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    class="scheduleboxLinks"
                    lg="7"
                    v-if="loggedin.status === '' || loggedin === ''"
                  >
                    <b-button to="/schedule" class="reportTourBtn"
                      >TOURNAMENT LIST</b-button
                    >
                    <b-button v-if="code == 'STQF' " href="https://updated.sunshinetour.com/q-school-visit-sa-open/" target="_blank" class="reportTourBtn portalBTN"
                      >ONLINE ENTRIES PORTAL</b-button
                    >
                    <b-button v-else-if="code== 'VTRS' " href="https://updated.sunshinetour.com/q-school-visit-sa-open/" target="_blank" class="reportTourBtn portalBTN"
                      >VUSI NGUBENI PORTAL</b-button
                    >
                  </b-col>
                  <b-col lg="6" v-else>
                    <template
                      v-if="
                        tourinfo.mosrestrict_ind == 'O' ||
                        tourinfo.code == 'STQF'
                      "
                    >
                    </template>
                    <template v-else>
                      <b-button
                        class="memberButton"
                        v-b-modal.ew
                        variant="success"
                        v-if="tourinfo.entry_action === 'E'"
                      >
                        Enter
                      </b-button>
                      <b-button
                        class="memberButton"
                        v-b-modal.ew
                        variant="danger"
                        v-if="tourinfo.entry_action === 'W'"
                      >
                        Withdraw
                      </b-button>
                      <b-button
                        class="reportTourBtn"
                        v-if="tourinfo.entry_action === 'T'"
                      >
                        Call to Enter
                      </b-button>
                      <b-button
                        class="memberButton"
                        variant="success"
                        v-if="
                          tourinfo.checkin_status == 'N' &&
                          tourinfo.checkin_open == 'Y'
                        "
                      >
                        Check-in
                      </b-button>
                      <b-button
                        class="memberButton"
                        variant="success"
                        v-if="
                          (tourinfo.payment_allowed == 'Y' &&
                            tourinfo.fee_paid == 'N' &&
                            tourinfo.entry_status == 'E') ||
                          (tourinfo.entry_status == 'Q' &&
                            tourinfo.entry_feeowe > 0)
                        "
                      >
                        Pay
                      </b-button>
                      <b-modal id="ew" :title="tourinfo.full_name" hide-footer>
                        <b-row>
                          <b-col>
                            <template v-if="tourinfo.entry_action === 'E'">
                              <b-form @submit="onEnter">
                                <b-form-group
                                  id="input-group-2"
                                  label="Entry"
                                  label-for="input-2"
                                >
                                  <b-form-textarea
                                    id="input-2"
                                    v-model="form.text"
                                    placeholder="Entry Notes"
                                    maxlength="300"
                                    rows="6"
                                    required
                                  ></b-form-textarea>
                                </b-form-group>
                                <p>
                                  By clicking Confirm you are agreeing to the
                                  Terms and Conditions which can be found in
                                  your players handbook.
                                </p>
                                <b-button type="submit" variant="success"
                                  >Enter</b-button
                                >
                              </b-form>
                            </template>
                            <template v-if="tourinfo.entry_action === 'W'">
                              <b-form @submit="onWithdraw">
                                <b-form-group
                                  id="input-group-2"
                                  label="Withdraw"
                                  label-for="input-2"
                                >
                                  <b-form-textarea
                                    id="input-2"
                                    v-model="form.text"
                                    placeholder="Withdraw Reason"
                                    maxlength="300"
                                    rows="6"
                                    required
                                  ></b-form-textarea>
                                </b-form-group>
                                <p>
                                  By clicking Confirm you are agreeing to the
                                  Terms and Conditions which can be found in
                                  your players handbook.
                                </p>
                                <b-button type="submit" variant="danger"
                                  >Withdraw</b-button
                                >
                              </b-form>
                            </template>
                          </b-col>
                        </b-row>
                      </b-modal>
                    </template>
                  </b-col>
                  <b-col class="logoOverflow" lg="5">
                    <b-img
                      class="sponLogo"
                      :src="tourinfo.sponsor_logo"
                      v-if="tourinfo.sponsor_logo.length > 0"
                    ></b-img>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-row cols="yellowOuterBlock">
              <b-col cols="12" class="yellowBlock"></b-col>
            </b-row>
            <b-row class="toptournInfo">
              <template v-if="tourinfo.winner_found == 'Y'">
                <b-col
                  lg="4"
                  style="padding: 0"
                  class="winnerProfile-Image"
                  :style="{
                    backgroundImage:
                      'url(' + DEFIMAGESAMS3 + 'sst'+
                      '/media/photos/' +
                      tourinfo.winner_code +
                      '.jpg' +
                      ')',
                  }"
                >
                  <div class="over"></div>
                </b-col>
              </template>
              <template v-else-if="tourinfo.previous_winners == ''">
                <b-col
                  lg="4"
                  style="padding: 0"
                  class="courseimage-hide courseImg"
                  :style="{
                    backgroundImage:
                      'url(' + DEFIMAGESAMS3 + 'sst' + '/' + 'courses' + '/' +
                        id +
                        '/' +
                        tourinfo.course_code +
                        '/' +
                        tourinfo.course_image_file_1 +
                      ')',
                  }"
                >
                  <div class="over"></div>
                </b-col>
              </template>
              <template v-else>
                <b-col
                  lg="4"
                  class="winnerProfile-Image"
                  :style="{
                    backgroundImage:
                      'url(' +
                      DEFIMAGESAMS3 + 'sst' + '/media/photos/' +
                      tourinfo.previous_winners.previous_winners_entry.cc_code_1 +
                      '.jpg' +
                      ')',
                  }"
                >
                  <div class="over"></div>
                </b-col>
              </template>
              <b-col class="reportInfo" lg="8">
                <b-row class="dateTitle">
                  <b-col lg="8">
                    <h3 class="reportDate">
                      {{ tourinfo.dates }}
                    </h3>
                    <h3 class="reportTitle">
                      {{ tourinfo.full_name }}
                    </h3>
                    <h3 class="courseName">
                      {{ tourinfo.course_name }}
                    </h3>
                    <template v-if="tourinfo.winner_found == 'Y'">
                      <h3 class="courseName">
                        <span class="winnerTag">Winner:</span
                        >{{ tourinfo.winner_name }}
                      </h3>
                    </template>
                    <template v-else-if="tourinfo.winner_found == 'N' && tourinfo.previous_winners != ''">
                      <h3 class="courseName">
                        <span class="winnerTag">Defending Champion:</span
                        >{{ tourinfo.previous_winners.previous_winners_entry.cc_name_1 }}
                      </h3>
                    </template>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="secondInfo">
                  <b-col lg="4">
                    <p class="valueReport">
                      {{ tourinfo.rounds }}
                    </p>
                    <p class="listTourInfo">Number of Rounds</p>
                  </b-col>
                  <b-col lg="4" v-if="tourinfo.total_prize_fund.length">
                    <p class="valueReport">
                      {{ tourinfo.total_prize_fund }}
                    </p>
                    <p class="listTourInfo">Prize Fund</p>
                  </b-col>
                </b-row>
                <b-row class="secondInfo" v-if="PlayerLogged !== 'guest'">
                  <b-col lg="4">
                    <p class="listTourInfo">Entry Status</p>
                    <p class="valueReport">
                      <span v-if="tourinfo.entry_closed === 'Y'">
                        Entries Closed
                      </span>
                      <span v-else> Entries Open </span>
                    </p>
                  </b-col>
                  <b-col lg="4">
                    <p class="listTourInfo">Your Status</p>
                    <p
                      class="valueReport"
                      v-if="tourinfo.entry_status.length > 0"
                    >
                      {{ currentStatus(tourinfo.entry_status) }}
                    </p>
                    <p class="valueReport" v-else>N/A</p>
                  </b-col>
                  <b-col lg="4">
                    <p class="listTourInfo">Entry Fee</p>
                    <p class="valueReport">
                      {{ tourinfo.entry_fee }}
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    class="scheduleboxLinks"
                    lg="7"
                    v-if="loggedin.status === '' || loggedin === ''"
                  >
                    <template v-if="code == 'STQF'">
                      <b-button href="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/sst/2024/12/2025-26-Q-School-Entry-Form.pdf" target="_blank" class="reportTourBtn"
                        >Q-SCHOOL ENTRY FORM</b-button
                      >
                    </template>
                    <template v-else>
                      <b-button to="/schedule" class="reportTourBtn"
                        >TOURNAMENT LIST</b-button
                      >
                    </template>
                    <b-button v-if="code == 'STQF' " href="https://updated.sunshinetour.com/q-school-visit-sa-open/" target="_blank" class="reportTourBtn portalBTN"
                      >ONLINE ENTRIES PORTAL</b-button
                    >
                    <b-button v-else-if="code== 'VTRS' " href="https://updated.sunshinetour.com/q-school-visit-sa-open/" target="_blank" class="reportTourBtn portalBTN"
                      >VUSI NGUBENI PORTAL</b-button
                    >
                  </b-col>
                  <b-col lg="6" v-else>
                    <template
                      v-if="
                        tourinfo.mosrestrict_ind == 'O' ||
                        tourinfo.code == 'STQF'
                      "
                    >
                    </template>
                    <template v-else>
                      <b-button
                        class="memberButton"
                        v-b-modal.ew
                        variant="success"
                        v-if="tourinfo.entry_action === 'E'"
                      >
                        Enter
                      </b-button>
                      <b-button
                        class="memberButton"
                        v-b-modal.ew
                        variant="danger"
                        v-if="tourinfo.entry_action === 'W'"
                      >
                        Withdraw
                      </b-button>
                      <b-button
                        class="reportTourBtn"
                        v-if="tourinfo.entry_action === 'T'"
                      >
                        Call to Enter
                      </b-button>
                      <b-button
                        class="memberButton"
                        variant="success"
                        v-if="
                          tourinfo.checkin_status == 'N' &&
                          tourinfo.checkin_open == 'Y'
                        "
                      >
                        Check-in
                      </b-button>
                      <b-button
                        class="memberButton"
                        variant="success"
                        v-if="
                          (tourinfo.payment_allowed == 'Y' &&
                            tourinfo.fee_paid == 'N' &&
                            tourinfo.entry_status == 'E') ||
                          (tourinfo.entry_status == 'Q' &&
                            tourinfo.entry_feeowe > 0)
                        "
                      >
                        Pay
                      </b-button>
                      <b-modal id="ew" :title="tourinfo.full_name" hide-footer>
                        <b-row>
                          <b-col>
                            <template v-if="tourinfo.entry_action === 'E'">
                              <b-form @submit="onEnter">
                                <b-form-group
                                  id="input-group-2"
                                  label="Entry"
                                  label-for="input-2"
                                >
                                  <b-form-textarea
                                    id="input-2"
                                    v-model="form.text"
                                    placeholder="Entry Notes"
                                    maxlength="300"
                                    rows="6"
                                    required
                                  ></b-form-textarea>
                                </b-form-group>
                                <p>
                                  By clicking Confirm you are agreeing to the
                                  Terms and Conditions which can be found in
                                  your players handbook.
                                </p>
                                <b-button type="submit" variant="success"
                                  >Enter</b-button
                                >
                              </b-form>
                            </template>
                            <template v-if="tourinfo.entry_action === 'W'">
                              <b-form @submit="onWithdraw">
                                <b-form-group
                                  id="input-group-2"
                                  label="Withdraw"
                                  label-for="input-2"
                                >
                                  <b-form-textarea
                                    id="input-2"
                                    v-model="form.text"
                                    placeholder="Withdraw Reason"
                                    maxlength="300"
                                    rows="6"
                                    required
                                  ></b-form-textarea>
                                </b-form-group>
                                <p>
                                  By clicking Confirm you are agreeing to the
                                  Terms and Conditions which can be found in
                                  your players handbook.
                                </p>
                                <b-button type="submit" variant="danger"
                                  >Withdraw</b-button
                                >
                              </b-form>
                            </template>
                          </b-col>
                        </b-row>
                      </b-modal>
                    </template>
                  </b-col>
                  <b-col class="logoOverflow" lg="5">
                    <b-img
                      class="sponLogo"
                      :src="tourinfo.sponsor_logo"
                      v-if="tourinfo.sponsor_logo.length > 0"
                    ></b-img>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>
    </template>
    <template v-else>
      <div
        class="sectionBackground"
        :style="{
          backgroundImage:
            'url(' +
            'https://updated.sunshinetour.com/wp-content/uploads/2019/12/course_dummy.png' +
            ')',
        }"
      >
        <b-container>
          <b-row class="topPad">
            <!-- <b-col>
                        <b-button
                            :to="{
                            name: 'schedule',
                            query: {
                                id: id,
                                code: course
                            }
                            }"
                            class="backBtn"
                            >BACK TO SCHEDULE</b-button
                        >
                        </b-col> -->
          </b-row>
          <div v-if="isMobile(true)">
            <b-row cols="yellowOuterBlock">
              <b-col cols="12" class="yellowBlock"></b-col>
            </b-row>
            <b-row class="toptournInfo">
              <template v-if="tourinfo.winner_found == 'Y'">
                <b-col
                  lg="4"
                  class="winnerProfile-Image"
                  :style="{
                    backgroundImage:
                      'url(' +
                      'https://sunshinetour.info/profiles/photos/' +
                      tourinfo.winner_code +
                      '.jpg' +
                      ')',
                  }"
                >
                  <div class="over"></div>
                </b-col>
              </template>
              <template v-else>
                <b-col
                  class="backgroundTourImg courseCodeImage"
                  lg="4"
                  :style="{
                    backgroundImage:
                      'url(' +
                      config.VUE_APP_PMWS_LOGO +
                      this.id +
                      '/' +
                      tourinfo.course_code +
                      '/' +
                      tourinfo.course_image_file_1 +
                      ')',
                  }"
                >
                </b-col>
              </template>
              <b-col class="reportInfo" lg="8" >
                <b-row class="dateTitle">
                  <b-col lg="8">
                    <h3 class="reportDate">
                      {{ tourinfo.dates }}
                    </h3>
                    <h3 class="reportTitle">
                      {{ tourinfo.full_name }}
                    </h3>
                    <h3 class="courseName">
                      {{ tourinfo.course_name }}
                    </h3>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="secondInfo">
                  <b-col lg="4">
                    <p class="valueReport">
                      {{ tourinfo.rounds }}
                    </p>
                    <p class="listTourInfo">Number of Rounds</p>
                  </b-col>
                  <b-col lg="4" v-if="tourinfo.total_prize_fund.length">
                    <p class="valueReport">
                      {{ tourinfo.total_prize_fund }}
                    </p>
                    <p class="listTourInfo">Prize Fund</p>
                  </b-col>
                </b-row>
                <b-row class="secondInfo" v-if="PlayerLogged !== 'guest'">
                  <b-col lg="4">
                    <p class="listTourInfo">Entry Status</p>
                    <p class="valueReport">
                      <span v-if="tourinfo.entry_closed === 'Y'">
                        Entries Closed
                      </span>
                      <span v-else> Entries Open </span>
                    </p>
                  </b-col>
                  <b-col lg="4">
                    <p class="listTourInfo">Your Status</p>
                    <p
                      class="valueReport"
                      v-if="tourinfo.entry_status.length > 0"
                    >
                      {{ currentStatus(tourinfo.entry_status) }}
                    </p>
                    <p class="valueReport" v-else>N/A</p>
                  </b-col>
                  <b-col lg="4">
                    <p class="listTourInfo">Entry Fee</p>
                    <p class="valueReport">
                      {{ tourinfo.entry_fee }}
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    class="scheduleboxLinks"
                    lg="7"
                    v-if="loggedin.status === '' || loggedin === ''"
                  >
                    <b-button to="/schedule" class="reportTourBtn"
                      >TOURNAMENT LIST</b-button
                    >
                    <b-button v-if="code == 'STQF' " href="https://updated.sunshinetour.com/q-school-visit-sa-open/" target="_blank" class="reportTourBtn portalBTN"
                      >ONLINE ENTRIES PORTAL</b-button
                    >
                    <b-button v-else-if="code== 'VTRS' " href="https://updated.sunshinetour.com/q-school-visit-sa-open/" target="_blank" class="reportTourBtn portalBTN"
                      >VUSI NGUBENI PORTAL</b-button
                    >
                  </b-col>
                  <b-col lg="6" v-else>
                    <template
                      v-if="
                        tourinfo.mosrestrict_ind == 'O' ||
                        tourinfo.code == 'STQF'
                      "
                    >
                    </template>
                    <template v-else>
                      <b-button
                        class="memberButton"
                        v-b-modal.ew
                        variant="success"
                        v-if="tourinfo.entry_action === 'E'"
                      >
                        Enter
                      </b-button>
                      <b-button
                        class="memberButton"
                        v-b-modal.ew
                        variant="danger"
                        v-if="tourinfo.entry_action === 'W'"
                      >
                        Withdraw
                      </b-button>
                      <b-button
                        class="reportTourBtn"
                        v-if="tourinfo.entry_action === 'T'"
                      >
                        Call to Enter
                      </b-button>
                      <b-button
                        class="memberButton"
                        variant="success"
                        v-if="
                          tourinfo.checkin_status == 'N' &&
                          tourinfo.checkin_open == 'Y'
                        "
                      >
                        Check-in
                      </b-button>
                      <b-button
                        class="memberButton"
                        variant="success"
                        v-if="
                          (tourinfo.payment_allowed == 'Y' &&
                            tourinfo.fee_paid == 'N' &&
                            tourinfo.entry_status == 'E') ||
                          (tourinfo.entry_status == 'Q' &&
                            tourinfo.entry_feeowe > 0)
                        "
                      >
                        Pay
                      </b-button>
                      <b-modal id="ew" :title="tourinfo.full_name" hide-footer>
                        <b-row>
                          <b-col>
                            <template v-if="tourinfo.entry_action === 'E'">
                              <b-form @submit="onEnter">
                                <b-form-group
                                  id="input-group-2"
                                  label="Entry"
                                  label-for="input-2"
                                >
                                  <b-form-textarea
                                    id="input-2"
                                    v-model="form.text"
                                    placeholder="Entry Notes"
                                    maxlength="300"
                                    rows="6"
                                    required
                                  ></b-form-textarea>
                                </b-form-group>
                                <p>
                                  By clicking Confirm you are agreeing to the
                                  Terms and Conditions which can be found in
                                  your players handbook.
                                </p>
                                <b-button type="submit" variant="success"
                                  >Enter</b-button
                                >
                              </b-form>
                            </template>
                            <template v-if="tourinfo.entry_action === 'W'">
                              <b-form @submit="onWithdraw">
                                <b-form-group
                                  id="input-group-2"
                                  label="Withdraw"
                                  label-for="input-2"
                                >
                                  <b-form-textarea
                                    id="input-2"
                                    v-model="form.text"
                                    placeholder="Withdraw Reason"
                                    maxlength="300"
                                    rows="6"
                                    required
                                  ></b-form-textarea>
                                </b-form-group>
                                <p>
                                  By clicking Confirm you are agreeing to the
                                  Terms and Conditions which can be found in
                                  your players handbook.
                                </p>
                                <b-button type="submit" variant="danger"
                                  >Withdraw</b-button
                                >
                              </b-form>
                            </template>
                          </b-col>
                        </b-row>
                      </b-modal>
                    </template>
                  </b-col>
                  <b-col class="logoOverflow" lg="5">
                    <b-img
                      class="sponLogo"
                      :src="tourinfo.sponsor_logo"
                      v-if="tourinfo.sponsor_logo.length > 0"
                    ></b-img>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-row cols="yellowOuterBlock">
              <b-col cols="12" class="yellowBlock"></b-col>
            </b-row>
            <b-row class="toptournInfo">
              <template v-if="tourinfo.winner_found == 'Y'">
                <b-col
                  lg="4"
                  style="padding: 0"
                  class="winnerProfile-Image"
                  :style="{
                    backgroundImage:
                      'url(' +
                      'https://sunshinetour.info/profiles/photos/' +
                      tourinfo.winner_code +
                      '.jpg' +
                      ')',
                  }"
                >
                  <div class="over"></div>
                </b-col>
              </template>
              <template v-else-if="tourinfo.course_images == 'Y'">
                <b-col
                  lg="4"
                  style="padding: 0"
                  class="courseimage-hide courseImg"
                  :style="{
                    backgroundImage:
                      'url(' +
                      'https://sunshinetour.info/pmws-logos/courses/' +
                      id +
                      '/' +
                      tourinfo.course_code +
                      '/' +
                      tourinfo.course_image_file_1 +
                      ')',
                  }"
                >
                  <div class="over"></div>
                </b-col>
              </template>
              <template v-else>
                <b-col
                  lg="4"
                  style="padding: 0"
                  class="courseimage-hide courseImg"
                  :style="{
                    backgroundImage:
                      'url(https://updated.sunshinetour.com/wp-content/uploads/2019/12/course_dummy.png)',
                  }"
                >
                  <div class="over"></div>
                </b-col>
              </template>
              <b-col class="reportInfo" lg="8">
                <b-row class="dateTitle">
                  <b-col lg="8">
                    <h3 class="reportDate">
                      {{ tourinfo.dates }}
                    </h3>
                    <h3 class="reportTitle">
                      {{ tourinfo.full_name }}
                    </h3>
                    <h3 class="courseName">
                      {{ tourinfo.course_name }}
                    </h3>
                    <h3 class="courseName" v-if="tourinfo.winner_found == 'Y'">
                      <span class="winnerTag">Winner:</span
                      >{{ tourinfo.winner_name }}
                    </h3>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="secondInfo">
                  <b-col lg="4">
                    <p class="valueReport">
                      {{ tourinfo.rounds }}
                    </p>
                    <p class="listTourInfo">Number of Rounds</p>
                  </b-col>
                  <b-col lg="4" v-if="tourinfo.total_prize_fund.length">
                    <p class="valueReport">
                      {{ tourinfo.total_prize_fund }}
                    </p>
                    <p class="listTourInfo">Prize Fund</p>
                  </b-col>
                </b-row>
                <b-row class="secondInfo" v-if="PlayerLogged !== 'guest'">
                  <b-col lg="4">
                    <p class="listTourInfo">Entry Status</p>
                    <p class="valueReport">
                      <span v-if="tourinfo.entry_closed === 'Y'">
                        Entries Closed
                      </span>
                      <span v-else> Entries Open </span>
                    </p>
                  </b-col>
                  <b-col lg="4">
                    <p class="listTourInfo">Your Status</p>
                    <p
                      class="valueReport"
                      v-if="tourinfo.entry_status.length > 0"
                    >
                      {{ currentStatus(tourinfo.entry_status) }}
                    </p>
                    <p class="valueReport" v-else>N/A</p>
                  </b-col>
                  <b-col lg="4">
                    <p class="listTourInfo">Entry Fee</p>
                    <p class="valueReport">
                      {{ tourinfo.entry_fee }}
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    class="scheduleboxLinks"
                    lg="7"
                    v-if="loggedin.status === '' || loggedin === ''"
                  >
                    <b-button to="/schedule" class="reportTourBtn"
                      >TOURNAMENT LIST</b-button
                    >
                    <b-button v-if="code == 'STQF' " href="https://updated.sunshinetour.com/q-school-visit-sa-open/" target="_blank" class="reportTourBtn portalBTN"
                      >ONLINE ENTRIES PORTAL</b-button
                    >
                    <b-button v-else-if="code== 'VTRS' " href="https://updated.sunshinetour.com/q-school-visit-sa-open/" target="_blank" class="reportTourBtn portalBTN"
                      >VUSI NGUBENI PORTAL</b-button
                    >
                  </b-col>
                  <b-col lg="6" v-else>
                    <template
                      v-if="
                        tourinfo.mosrestrict_ind == 'O' ||
                        tourinfo.code == 'STQF'
                      "
                    >
                    </template>
                    <template v-else>
                      <b-button
                        class="memberButton"
                        v-b-modal.ew
                        variant="success"
                        v-if="tourinfo.entry_action === 'E'"
                      >
                        Enter
                      </b-button>
                      <b-button
                        class="memberButton"
                        v-b-modal.ew
                        variant="danger"
                        v-if="tourinfo.entry_action === 'W'"
                      >
                        Withdraw
                      </b-button>
                      <b-button
                        class="reportTourBtn"
                        v-if="tourinfo.entry_action === 'T'"
                      >
                        Call to Enter
                      </b-button>
                      <b-button
                        class="memberButton"
                        variant="success"
                        v-if="
                          tourinfo.checkin_status == 'N' &&
                          tourinfo.checkin_open == 'Y'
                        "
                      >
                        Check-in
                      </b-button>
                      <b-button
                        class="memberButton"
                        variant="success"
                        v-if="
                          (tourinfo.payment_allowed == 'Y' &&
                            tourinfo.fee_paid == 'N' &&
                            tourinfo.entry_status == 'E') ||
                          (tourinfo.entry_status == 'Q' &&
                            tourinfo.entry_feeowe > 0)
                        "
                      >
                        Pay
                      </b-button>
                      <b-modal id="ew" :title="tourinfo.full_name" hide-footer>
                        <b-row>
                          <b-col>
                            <template v-if="tourinfo.entry_action === 'E'">
                              <b-form @submit="onEnter">
                                <b-form-group
                                  id="input-group-2"
                                  label="Entry"
                                  label-for="input-2"
                                >
                                  <b-form-textarea
                                    id="input-2"
                                    v-model="form.text"
                                    placeholder="Entry Notes"
                                    maxlength="300"
                                    rows="6"
                                    required
                                  ></b-form-textarea>
                                </b-form-group>
                                <p>
                                  By clicking Confirm you are agreeing to the
                                  Terms and Conditions which can be found in
                                  your players handbook.
                                </p>
                                <b-button type="submit" variant="success"
                                  >Enter</b-button
                                >
                              </b-form>
                            </template>
                            <template v-if="tourinfo.entry_action === 'W'">
                              <b-form @submit="onWithdraw">
                                <b-form-group
                                  id="input-group-2"
                                  label="Withdraw"
                                  label-for="input-2"
                                >
                                  <b-form-textarea
                                    id="input-2"
                                    v-model="form.text"
                                    placeholder="Withdraw Reason"
                                    maxlength="300"
                                    rows="6"
                                    required
                                  ></b-form-textarea>
                                </b-form-group>
                                <p>
                                  By clicking Confirm you are agreeing to the
                                  Terms and Conditions which can be found in
                                  your players handbook.
                                </p>
                                <b-button type="submit" variant="danger"
                                  >Withdraw</b-button
                                >
                              </b-form>
                            </template>
                          </b-col>
                        </b-row>
                      </b-modal>
                    </template>
                  </b-col>
                  <b-col class="logoOverflow" lg="5">
                    <b-img
                      class="sponLogo"
                      :src="tourinfo.sponsor_logo"
                      v-if="tourinfo.sponsor_logo.length > 0"
                    ></b-img>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>
    </template>
    <b-container style="padding-bottom: 50px">
      <div class="tourInfo">
        <b-row>
          <b-col v-if="tourinfo.sponsor_website.length">
            <p class="secondList">Sponsor Website</p>
            <p class="secondLead">
              <span>
                <a
                  style="color: #f2af15 !important"
                  :href="tourinfo.sponsor_website"
                  target="_blank"
                >
                  {{ tourinfo.sponsor_website }}
                </a>
              </span>
            </p>
          </b-col>
          <b-col v-if="PlayerLogged !== 'guest'">
            <b-nav class="dropdownSched" v-if="tourinfo.fact_sheets !== null">
              <b-dropdown
                class="memberTournDocs"
                text="Member Tournament Documents"
                v-if="Array.isArray(tourinfo.fact_sheets.fact_sheet)"
              >
                <b-dropdown-item
                  class="textPos"
                  v-for="(facts, index) in tourinfo.fact_sheets.fact_sheet"
                  :key="index"
                  title="Season Documents"
                  :href="facts.fact_sheet_file"
                  target="_blank"
                  v-show="facts.fact_sheet_desc.length"
                  >{{ facts.fact_sheet_desc }}</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown
                class="memberTournDocs"
                text="Member Tournament Documents"
                v-else
              >
                <b-dropdown-item
                  class="textPos"
                  v-for="(facts, index) in tourinfo.fact_sheets"
                  :key="index"
                  title="Season Documents"
                  :href="facts.fact_sheet_file"
                  target="_blank"
                  v-show="facts.fact_sheet_desc.length"
                  >{{ facts.fact_sheet_desc }}</b-dropdown-item
                >
              </b-dropdown>
            </b-nav>
          </b-col>
        </b-row>
      </div>
      <div class="tourInfo" v-if="tourinfo.tourn_desc_found == 'Y'">
        <h2 class="tourInfoHeading">Overview</h2>
          <p v-html="tourinfo.tourn_desc">
        </p>
      </div>
      <div class="tourInfo" v-if="tourinfo.course_para1.length > 0">
        <h2 class="tourInfoHeading">About</h2>
        <p v-if="tourinfo.course_para1.length">
          {{ tourinfo.course_para1 }}
        </p>
        <p v-if="tourinfo.course_para2.length">
          {{ tourinfo.course_para2 }}
        </p>
        <p v-if="tourinfo.course_para3.length">
          {{ tourinfo.course_para3 }}
        </p>
        <p v-if="tourinfo.course_para4.length">
          {{ tourinfo.course_para4 }}
        </p>
      </div>
      <div class="tourInfo">
        <h2 class="tourInfoHeading">Course Card</h2>
        <template v-if="tourinfo.multi_course === 'N'">
          <b-row :class="courseStats">
            <b-col>
              <div class="table-responsive">
                <table class="courseinfo">
                  <thead>
                    <tr class="title">
                      <th class="titleText">Hole</th>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                      <th>8</th>
                      <th>9</th>
                      <th>out</th>
                      <th>10</th>
                      <th>11</th>
                      <th>12</th>
                      <th>13</th>
                      <th>14</th>
                      <th>15</th>
                      <th>16</th>
                      <th>17</th>
                      <th>18</th>
                      <th>In</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="titleText">Par</td>
                      <td
                        v-for="(number, index) in tourinfo.course_par
                          .split(',')
                          .slice(0, 9)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ tourinfo.course_out_par.slice(1, 5) }}
                      </td>
                      <td
                        v-for="(number, index) in tourinfo.course_par
                          .split(',')
                          .slice(9, 18)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ tourinfo.course_in_par.slice(1, 5) }}
                      </td>
                      <td>
                        {{ tourinfo.course_total_par }}
                      </td>
                    </tr>
                    <tr>
                      <td class="title titleText">Yards</td>
                      <td
                        class="title"
                        v-for="(number, index) in tourinfo.course_length_yards
                          .split(',')
                          .slice(0, 9)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td class="title">
                        {{ outVal(tourinfo.course_length_yards) }}
                      </td>
                      <td
                        class="title"
                        v-for="(number, index) in tourinfo.course_length_yards
                          .split(',')
                          .slice(9, 18)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td class="title">
                        {{ inVal(tourinfo.course_length_yards) }}
                      </td>
                      <td class="title">
                        {{ totalVal(tourinfo.course_length_yards) }}
                      </td>
                    </tr>
                    <tr>
                      <td class="titleText">Meters</td>
                      <td
                        v-for="(number, index) in tourinfo.course_length_meters
                          .split(',')
                          .slice(0, 9)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ outVal(tourinfo.course_length_meters) }}
                      </td>
                      <td
                        v-for="(number, index) in tourinfo.course_length_meters
                          .split(',')
                          .slice(9, 18)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ inVal(tourinfo.course_length_meters) }}
                      </td>
                      <td>
                        {{ totalVal(tourinfo.course_length_meters) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </template>
        <!-- <template v-if="tourinfo.multi_course === 'N' && tourinfo.show_gender == 'Y'">
          <div
            v-for="(multi, index) in tourinfo.courses.courses_entry"
            :key="index"
          >
            <h3 class="courseName_x">{{multi.course_X_name}}</h3>
            <b-row :class="courseStats">
              <b-col>
                <div class="table-responsive">
                  <table class="courseinfo">
                    <thead>
                      <tr class="title" :class="multi.course_X_colour">
                        <th class="titleText">Hole</th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th>7</th>
                        <th>8</th>
                        <th>9</th>
                        <th>out</th>
                        <th>10</th>
                        <th>11</th>
                        <th>12</th>
                        <th>13</th>
                        <th>14</th>
                        <th>15</th>
                        <th>16</th>
                        <th>17</th>
                        <th>18</th>
                        <th>In</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="titleText">Par</td>
                        <td
                          v-for="(number, index) in multi.course_X_par
                            .split(',')
                            .slice(0, 9)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ multi.course_X_out_par.slice(1, 5) }}
                        </td>
                        <td
                          v-for="(number, index) in multi.course_X_par
                            .split(',')
                            .slice(9, 18)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ multi.course_X_in_par.slice(1, 5) }}
                        </td>
                        <td>
                          {{ multi.course_X_total_par }}
                        </td>
                      </tr>
                      <tr>
                        <td class="titleText">Yards</td>
                        <td
                          class="title"
                          v-for="(number, index) in multi.course_X_length_yards
                            .split(',')
                            .slice(0, 9)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td class="title">
                          {{ outVal(multi.course_X_length_yards) }}
                        </td>
                        <td
                          class="title"
                          v-for="(number, index) in multi.course_X_length_yards
                            .split(',')
                            .slice(9, 18)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td class="title">
                          {{ inVal(multi.course_X_length_yards) }}
                        </td>
                        <td class="title">
                          {{ totalVal(multi.course_X_length_yards) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="titleText">Meters</td>
                        <td
                          v-for="(number, index) in multi.course_X_length_meters
                            .split(',')
                            .slice(0, 9)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ outVal(multi.course_X_length_meters) }}
                        </td>
                        <td
                          v-for="(number, index) in multi.course_X_length_meters
                            .split(',')
                            .slice(9, 18)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ inVal(multi.course_X_length_meters) }}
                        </td>
                        <td>
                          {{ totalVal(multi.course_X_length_meters) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </div>
        </template> -->
        <template v-if="tourinfo.multi_course === 'Y'">
          <div
            v-for="(multi, index) in tourinfo.courses.courses_entry"
            :key="index"
          >
            <b-row :class="courseStats">
              <b-col>
                <div class="table-responsive">
                  <table class="courseinfo">
                    <thead>
                      <tr class="title" :class="multi.course_X_colour">
                        <th class="titleText">Hole</th>
                        <th>1</th>
                        <th>2</th>
                        <th>3</th>
                        <th>4</th>
                        <th>5</th>
                        <th>6</th>
                        <th>7</th>
                        <th>8</th>
                        <th>9</th>
                        <th>out</th>
                        <th>10</th>
                        <th>11</th>
                        <th>12</th>
                        <th>13</th>
                        <th>14</th>
                        <th>15</th>
                        <th>16</th>
                        <th>17</th>
                        <th>18</th>
                        <th>In</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="titleText">Par</td>
                        <td
                          v-for="(number, index) in multi.course_X_par
                            .split(',')
                            .slice(0, 9)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ multi.course_X_out_par.slice(1, 5) }}
                        </td>
                        <td
                          v-for="(number, index) in multi.course_X_par
                            .split(',')
                            .slice(9, 18)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ multi.course_X_in_par.slice(1, 5) }}
                        </td>
                        <td>
                          {{ multi.course_X_total_par }}
                        </td>
                      </tr>
                      <tr>
                        <td class="titleText">Yards</td>
                        <td
                          class="title"
                          v-for="(number, index) in multi.course_X_length_yards
                            .split(',')
                            .slice(0, 9)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td class="title">
                          {{ outVal(multi.course_X_length_yards) }}
                        </td>
                        <td
                          class="title"
                          v-for="(number, index) in multi.course_X_length_yards
                            .split(',')
                            .slice(9, 18)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td class="title">
                          {{ inVal(multi.course_X_length_yards) }}
                        </td>
                        <td class="title">
                          {{ totalVal(multi.course_X_length_yards) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="titleText">Meters</td>
                        <td
                          v-for="(number, index) in multi.course_X_length_meters
                            .split(',')
                            .slice(0, 9)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ outVal(multi.course_X_length_meters) }}
                        </td>
                        <td
                          v-for="(number, index) in multi.course_X_length_meters
                            .split(',')
                            .slice(9, 18)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ inVal(multi.course_X_length_meters) }}
                        </td>
                        <td>
                          {{ totalVal(multi.course_X_length_meters) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </div>
        </template>
      </div>
    </b-container>
    <template v-if="this.reportData === ''">
      <!-- No results -->
    </template>
    <template v-else>
      <div class="reportSele">
        <b-row>
          <b-col>
            <!-- <h3 class="selectReport">
                    SELECT REPORT
                    </h3> -->
            <div :class="drop">
              <b-nav card-header pills align="center">
                <b-dropdown
                  v-if="Array.isArray(tourinfo.reports.reports_entry)"
                  :text="title"
                  :config="config"
                >
                  <b-dropdown-item
                    v-on:click="changeReport"
                    v-for="(reports, index) in tourinfo.reports.reports_entry"
                    :key="index + Math.random()"
                    :title="reports.report_url"
                    :value="reports.report_title"
                    v-show="
                      reports.report_title !== 'Course Statistics' &&
                      reports.report_title !== 'Scoring Statistics' &&
                      reports.report_title !== 'Course Statistics (GPC)' &&
                      reports.report_title !== 'Course Statistics (LOST)' &&
                      reports.report_title !== 'Statistics Tables' &&
                      reports.report_title !== 'Live Scoring Monitor'
                    "
                    >{{ reports.report_title }}</b-dropdown-item
                  >
                </b-dropdown>
                <b-dropdown v-else :text="title">
                  <b-dropdown-item
                    v-on:click="changeReport"
                    v-for="(reports, index) in tourinfo.reports"
                    :key="index + Math.random()"
                    :title="reports.report_url"
                    :value="reports.report_title"
                    >{{ reports.report_title }}</b-dropdown-item
                  >
                </b-dropdown>
              </b-nav>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="multiCourseIndex" v-if="tourinfo.multi_course === 'Y'">
            <span
              v-for="(course, index) in tourinfo.courses.courses_entry"
              :key="index + Math.random()"
              class="multiText"
            >
              {{ course.course_X_id }} =
              <span :class="course.course_X_colour" class="multiDot-size"
                >•</span
              >
            </span>
          </b-col>
        </b-row>
      </div>
    </template>
  </div>
</template>
<script>
export default {
    name: "Tournament Information",
    props: [
        "config",
        "loggedin",
        "course",
        "title",
        "data",
        "tourinfo",
        "splash",
        "splashText",
        "logo",
        "overlay",
        "courseStats",
        "id",
        "code",
        "isMobile",
        "changeReport",
        "outVal",
        "inVal",
        "totalVal",
        "currentStatus",
        "onEnter",
        "onWithdraw",
        "onPay",
        "PlayerLogged",
        "reportData",
        "DEFIMAGESAMS3"
    ],
    data() {
        return {};
    },
    metaInfo() {
        return {
            title: this.tourinfo.full_name,
            meta: [
                { charset: "utf-8" },
                { name: "description", content: this.description },
            ],
        };
    }, 
};
</script>
<style scoped>
.scheduleboxLinks {
  display: flex;
}
.portalBTN {
  margin-left: 10px;
}
.courseName_x {
  color: #6C6C6C;
  font-weight: bold;
  font-size: 16px;
  margin-top: 30px;
}
.tourInfo.reportSection.container.tournReportSection {
  padding-left: 15px!important;
  padding-right: 15px!important;
}
.titleText {
  text-align: left;
}
.courseimage-hide.courseImg.col-lg-4 {
  background-repeat: no-repeat;
  background-size: cover;
}
.heightColorDiv {
  background: #006737;
  height: 30px;
  display: none;
}
span.winnerTag {
  font-weight: 600!important;
  padding-right: 5px;
}
.winnerProfile-Image.col-lg-4 {
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center;
}
.sectionBackground {
  background: url(https://updated.sunshinetour.com/wp-content/uploads/2022/07/grassback.png);
  background-repeat: no-repeat;
  padding-bottom: 7em;
  padding-top: 5em;
  background-size: cover;
  background-color: rgba(32 66 50 / 60%);
  background-blend-mode: multiply;
}
.over {
  overflow: hidden;
}
::v-deep img.courseImg {
  height: 340px;
  background-size: cover;
}
::v-deep .mt-3, .my-3 {
  margin-top: 0rem!important;
}
.reportSection {
  padding: 0px!important;
}
::v-deep a.dropdown-item {
  text-transform: capitalize;
  /* font-size: 14px; */
  padding: 0rem 1.5rem;
  border-radius: 0px;
  text-align: left;
  color: #000!important;
  font-size: 1rem;
}
/* ::v-deep .dropdown-item {
  text-transform: capitalize;;
  font-size: 14px;
  padding: 0rem 1.5rem;
  border-radius: 0px;
} */
::v-deep .dropdown-menu.show {
  display: block;
  width: 100%;
  text-align: center;
}
.courseinfo {
  width: 100%;
}
.row.row-cols-yellowouterblock {
  height: 20px;
  background-color: #F2AF14;
  border-radius: 10px 10px 0px 0px;
  margin-left: -2px;
  margin-right: -2px;
}
.memberTournDocs > button {
  border-radius: 5px;
}
ul.nav.dropdownSched {
  float: right;
  margin-top: 8px;
}
.toptournInfo {
  margin-left: -2px;
  margin-right: -2px;
}
span.multiText {
  color: #016837;
  font-size: 15pt;
  font-weight: 700;
}
span.multiDot-size {
  position: relative;
  font-size: 65px !important;
  line-height: 0px !important;
  vertical-align: middle;
  top: -4px;
}
.multiCourseIndex {
  text-align: center;
  margin-top: 3em;
}
.B {
  color: #333333;
}
.Y {
  color: #d8db22;
}
.C {
  color: #1e90ff;
}
.R {
  color: #bb0000;
}
.G {
  color: #3c9933;
}
.O {
  color: #e07020;
}

.B > th {
  background-color: #333333 !important;
}
.Y > th {
  background-color: #d8db22 !important;
}
.C > th {
  background-color: #1e90ff !important;
}
.R > th {
  background-color: #bb0000 !important;
}
.G > th {
  background-color: #3c9933 !important;
}
.O > th {
  background-color: #e07020 !important;
}
.lastRealTime-update {
  margin-top: 30px;
  margin-bottom: 2em;
}
::v-deep .tabsLi > div > ul > li {
  margin-right: 20px;
}
::v-deep .nav-tabs {
  border-bottom: 0;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f2af15;
  /* border-color: #dee2e6 #dee2e6 #fff; */
  color: #fff !important;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 0px;
}
::v-deep .nav-tabs .nav-link {
  padding-left: 30px;
  padding-right: 30px;
  background-color: #026034;
  border-radius: 0px;
  color: #fff !important;
  border: 0;
}
.TitleArea {
  margin-bottom: 0px;
  margin-top: 5px;
  display: none;
}
.TitleAreaScore {
  margin-bottom: 25px;
  margin-top: 40px;
}
h3.courseDates {
  color: #026034;
  font-weight: bold;
  font-size: 14px;
  text-align: left !important;
}
::v-deep .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  float: right;
  margin-top: 10px;
}
.splash {
  width: 100%;
}
.topPad {
  padding-top: 2rem;
}
.backBtn {
  background: rgb(242, 175, 20);
  padding: 10px;
  color: #fff !important;
  border: 0;
  font-size: 12pt;
  margin-bottom: 5em;
  border-radius: 0px;
}
.backBtn:hover {
  background: #006737;
  padding: 10px;
  color: #fff !important;
  border: 0;
  font-size: 12pt;
  margin-bottom: 5em;
  border-radius: 0px;
}
.reportInfo {
  background-color: #ffffff;
  border-radius: 0px 0px 0px 0px;
}
.tourInfo {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 2em;
  color: #6C6C6C;
  font-size: 13px;
  padding-left: 0px!important;
  padding-right: 0px!important;
}
.reportInfo {
  padding: 25px;
}
.dateTitle {
  /* padding-left: 35px; */
}
.reportDate {
  color: #f2af15;
  font-size: 13px;
  margin-bottom: 30px;
  margin-top: 25px;
  text-transform: capitalize;;
}

.reportTitle {
  color: #6C6C6C;
  font-weight: 700;
  font-size: 21px;
   
}
.courseName {
  color: #939393;
  font-size: 9pt;
  font-weight: 100;
  margin-top: 25px;
  display: block;
  font-weight: bold;
}
.logoOverflow {
  /* overflow: hidden;
  height: 210px; */
}
.sponLogo {
  max-height: 120px;
  width: auto;
  margin-top: -30px;
  float: right;
}
.secondInfo {
  margin-top: 30px;
}
.listTourInfo {
  color: #939393;
  margin-bottom: 0;
  text-align: left;
  font-size: 9pt;
}
.valueReport {
  color: #F2AF14;
  font-weight: 700;
  margin-bottom: 0;
  text-align: left;
}
.reportTourBtn {
  display: block;
  /* margin: auto; */
  background: #006737;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 200px;
  text-transform: capitalize;
  text-decoration: none !important;
  font-weight: 400;
  font-size: 11px;
  margin-top: 30px;
  height: 35px;
  line-height: 26px!important;
}
.reportTourBtn:hover {
  display: block;
  /* margin: auto; */
  background: #F2AF14;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 200px;
  text-transform: capitalize;
  text-decoration: none !important;
  font-weight: 400;
  font-size: 11px;
  margin-top: 30px;
  height: 35px;
  line-height: 26px!important;
}
.memberButton {
  display: block;
  margin: auto;
  border: none;
  color: #fff !important;
  border-radius: 0px;
  padding: 5px 20px 5px 20px;
  width: 200px;
  text-transform: capitalize;
  text-decoration: none !important;
}
.backgroundTourImg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0px 0px 0px 10px;
}
.secondList {
  margin-bottom: 0;
  text-align: left;
  color: #6C6C6C;
  font-weight: 700;
  font-size: 21px;
 
}
.secondLead {
  font-size: 1.25rem;
  font-weight: 300;
  text-align: left;
  color: #f2af15;
  margin-bottom: 0;
}
.tourInfoHeading {
  margin-top: 0px;
  padding-bottom: 0.3em;
  line-height: 1.25;
  color: #6C6C6C;
  font-weight: 700;
  font-size: 21px;
 
}
tr > th {
  padding: 0.55rem;
  vertical-align: top;
  border-top: 0 solid #dee2e6;
  background: #214232;
  color: #fff;
  /* font-size: 1rem; */
  font-size: 13px;
  text-align: center;
}
tr > td {
  padding: 0.55rem;
  vertical-align: top;
  border-top: 0 solid #dee2e6;
  background-color: rgba(0, 0, 0, 0.05);
  /* font-size: 1rem; */
  font-size: 13px;
  text-align: center;
}
.reportSele {
  padding: 20px;
  margin-top: 2em;
}
.selectReport {
  text-align: center;
  text-transform: capitalize;
  margin-top: 60px;
  line-height: 25px;
  margin-bottom: 25px;
  color: #6C6C6C;
  font-weight: 700;
  font-size: 21px;
}
::v-deep .dropdown > button {
  text-align: left;
  display: block;
  margin: auto;
  border-radius: 0;
  width: 475px;
  background-color: white;
  color: #495057;
  border: 1px solid #E0E0E0;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 13px;
}
.greenProfile {
  background-color: #006737;
}
::v-deep .modal-content {
  background-color: #f0f0f0;
}
::v-deep .modal-title {
  color: #026034;
}
::v-deep .modal-header {
  border-bottom: 0;
}
@media only screen and (min-width: 991px) {
  .courseImageHidDesk {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  .tourInfo {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-top: 0em;
    color: #6C6C6C;
    font-size: 13px;
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
  img.courseImg {
    height: 241px;
    background-size: cover;
  }
  .reportInfo {
    background-color: #ffffff;
    border-radius: 0px 0px 0px 0px;
  }
  .courseImageHid {
    display: none;
  }
  .backgroundTourImg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0px 0px 0px 0px;
    padding-left: 0px;
    overflow: hidden;
    height: 100%;
  }
  img.courseCodeImage {
    /* width: 100%; */
    height: 100px;
    min-height: 352px;
    border-radius: 0px 0px 0px 0px;
    min-width: 1050px;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .reportSele {
    padding: 20px;
    margin-top: -4em;
  }
  ul.nav.dropdownSched {
    float: none;
    margin-top: 8px;
  }
  ul.nav.dropdownSched > div {
    width: 100%;
  }
  .sponLogo {
    height: auto;
    /* width: 100%; */
    width: 100px;
    margin-top: 30px;
    float: right;
  }
  ::v-deep .reportTourBtn {
    display: block;
    /* margin: auto; */
    background: #006737;
    border: none;
    color: #fff!important;
    border-radius: 0;
    padding: 5px 20px;
    width: 100%;
    text-transform: capitalize;
    text-decoration: none !important;
    font-weight: 400;
    font-size: 11px;
    margin-top: 30px;
    height: 35px;
    line-height: 26px!important;
  }
  ::v-deep .reportTourBtn:hover {
    display: block;
    /* margin: auto; */
    background: #F2AF14;
    border: none;
    color: #fff!important;
    border-radius: 0;
    padding: 5px 20px;
    width: 100%;
    text-transform: capitalize;
    text-decoration: none !important;
    font-weight: 400;
    font-size: 11px;
    margin-top: 30px;
    height: 35px;
    line-height: 26px!important;
  }
  ::v-deep .logoOverflow {
    position: absolute;
    top: 13em;
    right: 1em;
  }
}

@media only screen and (max-width: 500px) {
  .dateTitle {
    padding-left: 0px;
  }
  ::v-deep .dropdown > button {
    text-align: left;
    display: block;
    margin: auto;
    border-radius: 0;
    width: 300px;
    background-color: white;
    color: #495057;
    border: 1px solid #E0E0E0;
    text-transform: capitalize;
    font-weight: 400;
  }
  ::v-deep .dropdown-toggle::after {
    display: inline-block;
    margin-left: 1.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    float: right;
    margin-top: 10px;
  }
}
</style>