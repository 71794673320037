
   
<template>
  <div>
    <b-container>
      <h2 class="mediaAForm">Media Accreditation Form</h2>
      <div class="request-a-callback-form">
        <transition name="fade" mode="out-in">
          <div v-show="sent" class="formMessage">
            <p>Thanks for contacting us, we will get back to you shortly...</p>
          </div>
        </transition>
        <b-form @submit="onSubmit">
          <b-row>
            <b-col md="6" cols="6" class="mobPad">
              <b-form-input
                type="text"
                v-model="names"
                placeholder="Your Name"
              />
              <br />
              <b-form-input
                type="text"
                v-model="company"
                placeholder="Company Name"
              />
              <br />
              <b-form-input
                type="text"
                v-model="job"
                placeholder="Job Title"
              />
              <br />
              <b-form-input
                type="text"
                v-model="email"
                placeholder="Email Address"
                required
              />
              <br />
              <b-form-input
                type="text"
                v-model="phone"
                placeholder="Phone Number"
              />
              <br />
            </b-col>
            <b-col md="6" cols="6" class="mobPad">
              <b-form-input
                type="text"
                v-model="tournament"
                placeholder="Name of Tournament/s you wish to attend"
              />
              <br>
              <!-- <b-form-input
                type="text"
                v-model="date"
                placeholder="Date of first arrival on site"
              /> -->
               <b-form-datepicker id="example-datepicker"  placeholder="Date of first arrival on site" v-model="date" class="mb-2"></b-form-datepicker>
              <br>
              <b-form-input
                type="text"
                v-model="regplate"
                placeholder="Vehicle licence registration number"
              />
              <!-- <br />
                <b-form-checkbox
                  type="checkbox"
                  v-for="optionsDrop in optionsDrop"
                  :value="optionsDrop"
                  v-model="options"
                  :key="optionsDrop"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-3a"
                >
                  {{ optionsDrop }}
                </b-form-checkbox>
              <br /> -->
              <br />
              <b-row>
                <b-col lg="12">
                  <h3 class="deskSpace">Desk space required in the media centre?</h3>
                  <b-form-checkbox
                    class="checkboxSize"
                    type="checkbox"
                    v-for="opt in optionsDrop"
                    :value="opt"
                    v-model="yourrecipient"
                    :key="opt"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-3a"
                  >
                    {{ opt.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <br />
            </b-col>

            <b-col md="12" cols="12">
              <!-- <b-form-textarea
                v-model="message"
                placeholder="Message"
                rows="11"
                cols="1"
                class="form-control"
              >
              </b-form-textarea> -->
              <br />
              <!-- <button
                data-text="submit"
                type="submit"
                class="moreBtn"
              >
                Submit
              </button> -->
                <button 
                type="submit" 
                class="btn btn-primary"
                data-text="submit"
                >
                    Submit
                </button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-container>
  </div>
</template>

<style scoped>
.formMessage {
  text-align: center;
  font-size: 17pt;
  color: #495057;
  border: 10px solid #006737;
  margin-bottom: 30px;
}
.formMessage > p {
  margin-bottom: 0px;
  padding-bottom: 25px;
  padding-top: 25px;
}
.mediaAForm {
  color: #6c6c6c;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  padding-bottom: 25px;
  text-align: center;
  padding-top: 25px;
}
.deskSpace {
    color: #495057;
    font-size: 18px;
}
.btn-primary {
    color: #fff;
    background-color: #204232;
    border-color: #204232;
    width: 100%;
}
.btn-primary:hover {
    color: #fff;
    background-color: #006837;
    border-color: #006837;
    width: 100%;
}
.blue {
    background-size: cover;
    padding-bottom: 5%;
    padding-top: 7%;
}
.moreBtn {
    border-color: transparent;
    font-size: 1.5rem;
    background-color: #00aeef;
    color: #f4f4f6;
    font-weight: 300;
    padding: 10px 75px;
    border-radius: 0;
}
::v-deep .form-control{
    font-size:18px
}
::v-deep .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    color: #6C6C6C;
    font-size: 13px;
}
::v-deep .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}
::v-deep .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    content: "";
    background: 50% / 50% 50% no-repeat;
}
@media only screen and (max-width: 480px) {
    .mobPad{
        padding-bottom: 50px;
    }
}
</style>

<script>
import axios from "axios";
export default {
  name: "Form",
  data() {
    return {
      sent: false,
      contact: true,
      names: "",
      company: "",
      job: "",
      email: "",
      phone: "",
      tournament: "",
      date: "",
      regplate: "",
      // options:"",
      optionsDrop: [
        {
          name: 'Yes',
          email: 'mediaaccreditation@sunshinetour.com'
        },
        {
          name: 'No',
          email: 'mediaaccreditation@sunshinetour.com'
        },
        // {
        //   name: '',
        //   email: 'news@vlismasmedia.co.za'
        // },
      ],
      // optionsDrop: [
      //   'Ticket Queries',
      //   'Event Spectator Queries',
      //   'Website and App Feedback Queries',
      //   'Competition Queries',
      //   'Qualifying School',
      //   'Vusi Ngubeni',
      //   'SA Open Championship',
      //   'Sunshine Ladies Tour',
      //   'Altron Big Easy Tour',
      //   'General Queries'
      // ],
      message: "",
    };
  },
  props: ["blue"],
  methods: {
    onSubmit(e) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("names", this.names);
      formData.append("company", this.company);
      formData.append("job", this.job);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("tournament", this.tournament);
      formData.append("date", this.date);
      formData.append("regplate", this.regplate);
      formData.append("yourrecipient", this.yourrecipient.name); 
      formData.append("yourrecipient", this.yourrecipient.email);
        // alert(JSON.stringify(this.formData))
      // console.log(formData);
      axios
        .post(
          "https://updated.sunshinetour.com/wp-json/contact-form-7/v1/contact-forms/39823/feedback",
          formData
        )
        .then((response) => {
          this.sent = true;
          this.names = "";
          this.email = "";
          this.phone = "";
          this.company = "";
          this.job = "";
          this.tournament = "";
          this.date = "";
          this.regplate = "";
          this.yourrecipient = "";
          this.yourrecipient = "";
          return response;
        });
    },
  },
};
</script>